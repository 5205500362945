import axios from "axios";
import { FIREBASE_BASE_URL } from "../utils";

export const uploadScreenshotToAWS = async (screenshot: string, userEmail: string) => {
    try {
      // Convert base64 string to Blob
      const base64Data = screenshot.split(",")[1];
      const blob = await fetch(screenshot).then(res => res.blob());
  
      // Get the signed URL from the backend
      const { data: { uploadUrl, key } } = await axios.get(
        `${FIREBASE_BASE_URL}/getS3ScreenshotUploadUrl`,
        {
          params: {
            fileType: "image/jpeg",
            userEmail: userEmail,
          },
        }
      );
  
      // Upload the screenshot directly to S3 using the signed URL
      await axios.put(uploadUrl, blob, {
        headers: { "Content-Type": "image/jpeg" },
      });
  
      console.log("Screenshot uploaded to AWS:", key);
  
      // Return the key (S3 object path) for future reference
      return { key };
    } catch (error) {
      console.error("Error uploading screenshot to AWS:", error);
      throw error;
    }
  };