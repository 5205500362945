import { useState, useEffect } from "react";
import { DropResult, DragDropContext } from "react-beautiful-dnd";
import { Confetti } from "solar-icon-set/essentionalui";
import { useModal } from "../../utils/components/ducks/modal/export";
import { useUpload } from "../../utils/components/ducks/project/progress-upload/export";
import KanbanColumn from "../../utils/components/ducks/steps/components/Kanban/KanbanColumn";
import { useSteps } from "../../utils/components/ducks/steps/export";
import { useAssistant } from "../../utils/components/ducks/assistant/export";

const BoardPage = () => {

    const { actions, steps, thunks } = useSteps();
    const { thunks: assistantThunks } = useAssistant();
    const upload = useUpload()
    const modal = useModal()
    const [showConfetti, setShowConfetti] = useState(false);
    const [recentUpload, setRecentUpload] = useState(false)
    const [isDragging, setIsDragging] = useState(false);

    const onDragStart = () => {
        setIsDragging(true);
    };

    // useEffect(() => {
    //     if (recentUpload) {
    //         setRecentUpload(false)
    //         setShowConfetti(true)
    //         setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
    //     }
    // }, [upload.files])

    const onDragEnd = (result: DropResult) => {
        setIsDragging(false);
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // Create a map of steps by status
        const stepsByStatus = steps.reduce((acc, step) => {
            if (!acc[step.status]) {
                acc[step.status] = [];
            }
            acc[step.status].push(step);
            return acc;
        }, {} as Record<string, typeof steps>);

        // Find the moved step
        const movedStep = steps.find(step => step.id === draggableId);
        if (!movedStep) return;

        // Remove from source
        stepsByStatus[source.droppableId] = stepsByStatus[source.droppableId].filter(
            step => step.id !== draggableId
        );

        // Add to destination
        const updatedStep = { ...movedStep, status: destination.droppableId as 'TODO' | 'DOING' | 'DONE' };

        console.log('updatedStep', updatedStep)

        thunks.moveStepThunk({ stepId: draggableId, destination: destination.droppableId as 'TODO' | 'DOING' | 'DONE' })

        switch (destination.droppableId) {
            case 'TODO':
                break;
            case 'DOING':
                assistantThunks.sendMessage(`I want start a step: ${movedStep.title}`)
                break;
            case 'DONE':
                assistantThunks.sendMessage(`I'm done with this step`)
                break;
        }

        // if (destination.droppableId === 'DONE') {
        //     setRecentUpload(true)
        //     upload.actions.setStep(updatedStep)
        //     modal.actions.setState('upload')
        // }
    };

    return (
        <main
            className="flex h-full w-full"
        >
            {showConfetti && <Confetti />}
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <div className="flex flex-row p-8 h-auto w-full gap-x-8">
                    <KanbanColumn status="TODO" isDraggable isDragging={isDragging} />
                    <KanbanColumn status="DOING" isDraggable isDragging={isDragging} />
                    <KanbanColumn status="DONE" isDraggable isDragging={isDragging} />
                </div>
            </DragDropContext>
            {/* <Button
                variant='primary'
                className="absolute bottom-6 right-6 px-8 py-4"
            >

                <h5>+ Add Task</h5>
            </Button> */}
        </main>
    )
}

export default BoardPage