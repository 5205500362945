import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../../grapes/atoms/button/Button";
import { RootState } from "../../../../init/reduxInit";
import { AssistantThunks } from "../../assistant/thunks";
import { AppDispatch } from "../../../../init/reduxInit"; 
import { API } from "../api";
import { useSession } from "../../session/export";
import { useUser } from "../export";

interface GuideThemedProjectButtonProps {
    projectTitle: string;
    email: string;
    projectDescription: string;
    projectDeliverable: string;
    projectStarted: boolean;
    guideName: string;
}

const GuideThemedProjectButton: React.FC<GuideThemedProjectButtonProps> = ({ projectTitle, email, projectDescription, projectDeliverable, projectStarted, guideName }) => {
    const dispatch = useDispatch<AppDispatch>();
    const user = useUser();
    const session = useSession();
    const guideThemedProjects = useSelector((state: RootState) => state.user.guideThemedProjects);

    console.log("projectStarted", projectStarted)

    if (guideThemedProjects.length === 0 || projectStarted) {
        return null;
    }

    const handleClick = () => {
        dispatch(AssistantThunks.sendMessage(`I am interested in ${projectTitle}`));
        API.updateProjectStarted({ learnerEmail: email, projectTitle: projectTitle, projectStarted: true });
        API.updateIsGuideTheme({ thread: session.data.thread!, isGuideTheme: true, guideTitle: projectTitle, guideDescription: projectDescription, guideDeliverable: projectDeliverable });
    };

    return (
        <Button
            variant="primary"
            onClick={handleClick}
            className="w-3/4 h-full"
        >
            {"Guide " + guideName || 'Your Guide'} shared a project with you: {projectTitle}.
            <br />
            Click to join!
        </Button>
    );
};

export default GuideThemedProjectButton;