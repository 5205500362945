import { client } from "../../../init/pathFinderInit";
import { toastSlice } from "../toast/slice";
import { Thunk } from "../utils";
import { functionMap, handleFunctionCall } from "../functions";
import { assistantSlice } from "./slice";
import { API } from "./api";
import { Message } from "./state";
import { panelSlice } from "../panel/slice";
import { widgetSlice } from "../widget/slice";
import { PanelState } from "../panel/state";
import { WidgetState } from "../widget/state";
import axios from "axios";
import mixpanel from "mixpanel-browser";

export namespace AssistantThunks {

    export const sendMessage: Thunk<string | null> = (message) => async (dispatch: any, getState: () => any) => {
        const messages = getState().assistant.messages
        console.log("messages: ", messages)

        // export const sendMessage: Thunk<{ message: string | null; extraMetaData?: Record<string, any> }> = ({ message, extraMetaData = {} }) => async (dispatch: any, getState: () => any) => {
        try {
            logger.log("[ASSISTANT THUNK] 🔵 Starting send message");
            if (getState().session.data.thread) {

                const thread = getState().session.data.thread!

                dispatch(assistantSlice.actions.setLoading("true"))
                dispatch(widgetSlice.actions.setState('none'))
                // dispatch(sessionSlice.actions.setMessagesCount(getState().session.data.messagesCount + 1))

                Promise.resolve().then(async () => {
                    try {
                        if (getState().auth.data.email) {
                            await API.updateLastLoggedIn(getState().auth.data.email, getState().session.data.thread!.thread_id);
                            logger.log("[ASSISTANT THUNK] 🟢 Updated last logged in time");
                        }
                        await API.updateLastMessaged(getState().session.data.thread!.thread_id);
                        logger.log("[ASSISTANT THUNK] 🟢 Updated last messaged time");
                        await API.incrementNumMessages(getState().session.data.thread!.thread_id);
                        logger.log("[ASSISTANT THUNK] 🟢 Updated number of messages");
                        if (messages.length === 2) {
                            const firstMessage = messages[0].content.message.replace(/^I am interested in\s*/i, "")
                            await API.saveProjectTitle(getState().session.data.thread!.thread_id, firstMessage);
                            logger.log("[ASSISTANT THUNK] 🟢 Updated project title");
                        }
                    } catch (error) {
                        logger.error("[ASSISTANT THUNK] 🔴 Failed to update last logged in time or increment numMessages", error);
                    }
                });

                dispatch(assistantSlice.actions.clearSuggestions())

                if (message !== null) {
                    dispatch(assistantSlice.actions.createMessage({
                        content: { message: message, fileUrl: getState().assistant.data.fileUrl! || "" },
                        role: "human"
                    }))
                }

                dispatch(assistantSlice.actions.createMessage({
                    content: { message: "" },
                    role: "ai"
                }))

                logger.log("[ASSISTANT THUNK] 🔵 Sending message to assistant", message, getState().assistant.data.fileUrl!);

                const streamResponse = API.stream(
                    thread.thread_id!,
                    message,
                    "human",
                    {
                        userEmail: getState().auth.data.email! || "",
                        uploadedImage: getState().assistant.data.fileUrl || "",
                        isSocratic: getState().socratic.isSocratic,
                        isScreenSharing: getState().screenShare.isScreenSharing
                    }
                )

                for await (const chunk of streamResponse) {
                    // Just a message
                    if (chunk.data.event === "on_chat_model_stream") {
                        dispatch(assistantSlice.actions.setLoading("streaming"))
                        dispatch(assistantSlice.actions.streamMessage({
                            content: {
                                message: chunk.data.data.chunk?.content || ''
                            },
                            role: "ai"
                        }))
                        // A tool
                    } else if ((chunk.data.event === "on_tool_start" || chunk.data.event === "on_tool_end") && chunk.data.name !== "getSuggestedActions") {
                        dispatch(assistantSlice.actions.setLoading("streaming"))
                        handleFunctionCall(dispatch, chunk.data.name, chunk.data.data.output?.content || "{}", false)
                        if (functionMap[chunk.data.name]?.state) {
                            dispatch(panelSlice.actions.setState(functionMap[chunk.data.name]!.state! as PanelState['state']))
                            dispatch(widgetSlice.actions.setState(functionMap[chunk.data.name]!.state! as WidgetState['state']))
                        }
                        if (chunk.data.data.output?.content.length > 0) {
                            dispatch(assistantSlice.actions.createMessage({
                                content: {
                                    message: ''
                                },
                                role: "tool",
                                tool: {
                                    name: chunk.data.name,
                                    content: chunk.data.data.output?.content,
                                    version: functionMap[chunk.data.name]?.versioning?.get?.(getState) || 0
                                }
                            }))
                            // allow the AI to keep adding to the message
                            dispatch(assistantSlice.actions.createMessage({
                                content: {
                                    message: ''
                                },
                                role: "ai",
                            }))
                        }
                    } else if (chunk.data.event === "on_custom_event") {
                        console.log("Event name: ", chunk.data.name)
                        if (chunk.data.name === "transition_state") {
                            handleFunctionCall(dispatch, "state_transition", chunk.data.data, false)
                            dispatch(assistantSlice.actions.createMessage({
                                content: {
                                    message: ''
                                },
                                role: "tool",
                                tool: {
                                    name: "state_transition",
                                    content: chunk.data.data,
                                    version: 0
                                }
                            }))
                            dispatch(assistantSlice.actions.createMessage({
                                content: {
                                    message: ''
                                },
                                role: "ai",
                            }))
                        } else {
                            console.log("Unknown custom event: ", chunk.data.name)
                        }
                    } else if ((chunk.data.event === "on_tool_start" || chunk.data.event === "on_tool_end") && chunk.data.name === "getSuggestedActions") {
                        handleFunctionCall(dispatch, chunk.data.name, chunk.data.data.output?.content || "{}", false)
                    } else if (chunk.data.event === "on_chain_end") {
                        if (chunk.data.data.output.messages) {
                            if (chunk.data?.data?.output?.messages[chunk.data.data.output.messages.length - 1]?.additional_kwargs?.error) {
                                // throw new Error(chunk.data.data.output.messages[chunk.data.data.output.messages.length - 1].additional_kwargs.error)
                                logger.log("[ASSISTANT THUNK] 🔴 Error sending message on_chain_end", chunk.data.data.output.messages[chunk.data.data.output.messages.length - 1].additional_kwargs.error);
                            } else {

                            }
                        }
                    }
                    if (chunk.data.error) {
                        throw new Error(chunk.data.message)
                    } else {
                        // logger.log(chunk.data) // FOR DEV ONLY
                    }

                }

                // get current state
                console.log("🟢 Getting thread state for tool_calls")
                try {
                    const state = await API.getThreadState(thread!.thread_id!)
                    console.log("state: ", state)

                    for (const nextState of state.next) {
                        handleFunctionCall(dispatch, nextState, "", false)
                    }
                    if (functionMap[state.next[state.next.length - 1]]?.state) {
                        dispatch(panelSlice.actions.setState(functionMap[state.next[state.next.length - 1]]!.state! as PanelState['state']))
                        dispatch(widgetSlice.actions.setState(functionMap[state.next[state.next.length - 1]]!.state! as WidgetState['state']))
                    }

                } catch (error) {
                    console.error("Error getting thread state:", error);
                    // You might want to dispatch an error action here
                }
            }
        } catch (error: any) {
            logger.log("[ASSISTANT THUNK] 🔴 Error sending message on_chat_model_stream", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to send the message.",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            logger.log("[ASSISTANT THUNK] 🟢 Send message complete");
            dispatch(assistantSlice.actions.setFileUrl(""))
            dispatch(assistantSlice.actions.setLoading("false"))
        }
    };

    export const sendToolCall: Thunk<{ toolName: string, content?: string }> = ({ toolName, content }) => async (dispatch, getState) => {
        try {
            logger.log("[ASSISTANT THUNK] 🔵 Starting send tool call");
            if (getState().session.data.thread) {

                const thread = getState().session.data.thread!

                const state = await API.getThreadState(thread.thread_id!)
                const toolCallId = state.values.messages[state.values.messages.length - 1].tool_calls[0].id;

                // We now create the tool call with the id and the response we want
                const toolMessages = [
                    {
                        tool_call_id: toolCallId,
                        "name": "upload",
                        "type": "tool",
                        "content": JSON.stringify(
                            {
                                "step_complete": true,
                                "answer": {
                                    "content": content,
                                    "type": "file"
                                }
                            }
                        )
                    }
                ];

                await client.threads.updateState(
                    thread.thread_id!,
                    {
                        values: {
                            messages: toolMessages
                        },
                        asNode: "upload",
                    }
                );
                dispatch(assistantSlice.actions.createMessage({
                    content: { message: "", fileUrl: "" },
                    tool: {
                        name: "upload",
                        content: JSON.stringify(
                            {
                                "step_complete": true,
                                "answer": {
                                    "content": content,
                                    "type": "file"
                                }
                            })
                    },
                    role: "tool"
                }))
                dispatch(sendMessage(null))

                // dispatch(sendMessage({ message: null, extraMetaData: { filename: "upload_complete" } }))

            }

        } catch (error: any) {
            logger.log("[ASSISTANT THUNK] 🔴 Error sending tool call", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to send the message.",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            logger.log("[ASSISTANT THUNK] 🟢 Send tool call complete");
            dispatch(assistantSlice.actions.setFileUrl(""))
            dispatch(assistantSlice.actions.setLoading("false"))
        }
    }

    // MAKE A THUNK TO RUN THE fucntions.ts 

    export const runFunction: Thunk<any> = (functionToRun: any) => async (dispatch, getState) => {
        dispatch(functionToRun);
    };

    export const getMessagesThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[ASSISTANT THUNK] 🎬 Getting messages");

        try {
            dispatch(assistantSlice.actions.setLoading("true"));

            const threadId = getState().session.data.thread!.thread_id;

            if (!threadId) {
                logger.log("[ASSISTANT THUNK] ⚠️ No thread ID found");
                return;
            }

            const response = await API.getThreadState(threadId);

            if (response.values) {
                logger.log("[ASSISTANT THUNK] ✅ Messages fetched successfully");
                if (response.values.messages) {
                    response.values.messages.map((msg: any) => {
                        let message: Message | undefined = undefined
                        if (msg.type === "tool") {
                            handleFunctionCall(dispatch, msg.name, msg.content, true)
                            message = {
                                role: "tool",
                                content: {
                                    message: "",
                                },
                                tool: {
                                    name: msg.name,
                                    content: msg.content,
                                    version: functionMap[msg.name]?.versioning?.get?.(getState) || 0
                                }
                            }
                        } else if (msg.type === "ai") {
                            if (msg.content !== "") {
                                message = {
                                    role: "ai",
                                    content: {
                                        message: msg.content,
                                    },
                                };
                            };
                        } else if (msg.type === "human") {
                            message = {
                                role: "human",
                                content: {
                                    message: msg.content,
                                },
                            };
                        }
                        if (message !== undefined) {
                            dispatch(assistantSlice.actions.setMessages([...getState().assistant.messages, message]));
                        }
                    })
                }
                // .filter((msg: Message | undefined): msg is Message => msg !== undefined);
            } else {
                throw new Error(response.data);
            }
        } catch (error: any) {
            logger.log("[ASSISTANT THUNK] 🔴 Error fetching messages", error);
            // dispatch(toastSlice.actions.setToast({
            //     type: "error",
            //     message: "Failed to fetch messages",
            //     error: error.message || "Unknown error occurred",
            //     fatal: false
            // }));
        } finally {
            dispatch(assistantSlice.actions.setLoading("false"));
            logger.log("[ASSISTANT THUNK] 🔚 Get messages completed");
        }
    };
    export const updateSuggestedActions: Thunk<any> = (suggestedActions: any) => async (dispatch, getState) => {
        try {
            logger.log("[ASSISTANT THUNK] 🔵 Starting update suggested actions", suggestedActions);
            // Parse the suggestedActions string into an array of strings
            const parsedSuggestedActions = JSON.parse(suggestedActions);
            // Validate that all elements are strings
            const validatedSuggestedActions = Array.isArray(parsedSuggestedActions)
                ? parsedSuggestedActions.filter((action: any) => typeof action === 'string')
                : [];
            logger.log("[ASSISTANT THUNK] 🔵 Validated suggested actions", validatedSuggestedActions);
            // Update suggestedActions with the validated array
            suggestedActions = validatedSuggestedActions;
            dispatch(assistantSlice.actions.setSuggestions(suggestedActions as string[] | null));
            logger.log("[ASSISTANT THUNK] 🟢 Successfully updated suggested actions");
        } catch (error) {
            logger.error("[ASSISTANT THUNK] 🔴 Error updating suggested actions", error);
        } finally {
            logger.log("[ASSISTANT THUNK] 🔚 Update suggested actions completed");
        }
    };

    export const getS3UploadUrlThunk: Thunk<File> = (file) => async (dispatch, getState) => {
        try {
            logger.log("[ASSISTANT THUNK] 🔵 Getting S3 upload URL");
            const link = await API.uploadFile(file, getState().session.data.thread!.thread_id!, getState().auth.data.email!, "");
            const { uploadUrl, key } = link;
            return { uploadUrl, key, type: file.type, name: file.name };
        } catch (error: any) {
            logger.error("[ASSISTANT THUNK] 🔴 Error getting S3 upload URL", error);
            throw error;
        }
    };

    export const uploadFileToS3Thunk: Thunk<any> = ({ file, bucket }) => async (dispatch) => {
        try {
            logger.log("[ASSISTANT THUNK] 🔵 Uploading file to S3");
            const response = await axios.put(bucket!.uploadUrl, file, {
                headers: { "Content-Type": file.type },
            });

            if (response.status === 200) {
                logger.log("[ASSISTANT THUNK] 🟢 File uploaded successfully.", bucket!.uploadUrl);
            } else {
                throw new Error("Failed to upload file to S3");
            }
        } catch (error: any) {
            logger.error("[ASSISTANT THUNK] 🔴 Error uploading file to S3", error);
            throw error;
        }
    };

    export const getS3ImageUrlThunk: Thunk<any> = ({ bucket }) => async (dispatch, getState) => {
        try {
            logger.log("[ASSISTANT THUNK] 🔵 Getting S3 image URL");
            const imageInfo = await API.getImageURLFromAWS(bucket!.key);
            logger.log("[ASSISTANT THUNK] 🟢 S3 image URL fetched successfully", imageInfo);
            return { fileUrl: imageInfo.fileUrl, contentType: imageInfo.contentType };
        } catch (error: any) {
            logger.error("[ASSISTANT THUNK] 🔴 Error getting S3 image URL", error);
            throw error;
        }
    };

    export const uploadChatFileThunk: Thunk<any> = ({ file }) => async (dispatch, getState) => {
        try {
            logger.log("[ASSISTANT THUNK] 🔵 Uploading file started");
            dispatch(assistantSlice.actions.setFileStatus('preparing'));
            const bucket = await dispatch(getS3UploadUrlThunk(file));
            dispatch(assistantSlice.actions.setFileStatus('uploading'));
            await dispatch(uploadFileToS3Thunk({ file, bucket }));
            const { fileUrl, contentType } = await dispatch(getS3ImageUrlThunk({ bucket }));
            dispatch(assistantSlice.actions.setFileUrl(fileUrl));
            dispatch(assistantSlice.actions.setFileStatus('uploaded'));
            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('Chat File Uploaded', { fileType: file.type, fileName: file.name });
            }
        } catch (error: any) {
            logger.error("[ASSISTANT THUNK] 🔴 Error in upload file process", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to upload file",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(assistantSlice.actions.setFileStatus('none'));
            logger.log("[ASSISTANT THUNK] 🟢 Uploaded file successfully");
        }
    };

    export const incrementTimesListenedThunk: Thunk<string> = (threadId) => async (dispatch) => {
        try {
            logger.log("[ASSISTANT THUNK] 🔵 Incrementing times listened");
            await API.incrementTimesListened(threadId);
            logger.log("[ASSISTANT THUNK] 🟢 Successfully incremented times listened");
        } catch (error) {
            logger.error("[ASSISTANT THUNK] 🔴 Error incrementing times listened", error);
        }
    };

}
