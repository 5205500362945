import { Dispatch } from "redux";
import { setIsScreenSharing, addScreenshot, setLastScreenshotTime } from "./slice";
import { uploadScreenshotToAWS } from "./api";

let isCapturing = false;
let captureStream: MediaStream | null = null;

export const startScreenShare = () => async (dispatch: Dispatch, getState: () => any) => {
    try {
      captureStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      console.log("Screen sharingg started");
      dispatch(setIsScreenSharing(true));
      isCapturing = true;
  
      captureStream.getVideoTracks()[0].addEventListener("ended", () => {
        dispatch(stopScreenShare() as any);
      });
  
      captureAndUploadScreenshots(dispatch, getState);
    } catch (error) {
      console.error("Error starting screen share:", error);
      dispatch(setIsScreenSharing(false));
      if (error instanceof DOMException && error.name === "NotAllowedError") {
        alert("Screen capture permission was denied. Please allow screen sharing to use this feature.");
      } else {
        alert("An error occurred while trying to start screen sharing. Please try again.");
      }
    }
};

export const stopScreenShare = () => (dispatch: Dispatch) => {
    console.log("Screen sharing stopped");
    dispatch(setIsScreenSharing(false));
    isCapturing = false;
    if (captureStream) {
      captureStream.getTracks().forEach(track => track.stop());
      captureStream = null;
    }
};

const captureAndUploadScreenshots = async (dispatch: Dispatch, getState: () => any) => {
    if (!captureStream) {
        console.error("No capture stream available");
        return;
    }

    const videoTrack = captureStream.getVideoTracks()[0];
    const videoElement = document.createElement('video');
    videoElement.srcObject = new MediaStream([videoTrack]);
    await videoElement.play();

    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const ctx = canvas.getContext('2d');

    try {
        while (isCapturing) {
        if (ctx) {
            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
            const screenshot = canvas.toDataURL('image/jpeg');
            await uploadScreenshot(dispatch, getState, screenshot);
        }
        await new Promise(resolve => setTimeout(resolve, 3000)); // take ss after 3 seconds
        }
    } catch (error) {
        console.error("Error capturing screen:", error);
        dispatch(setIsScreenSharing(false));
    } finally {
        if (captureStream) {
        captureStream.getTracks().forEach(track => track.stop());
        captureStream = null;
        }
    }
};
  
const uploadScreenshot = async (dispatch: Dispatch, getState: () => any, screenshot: string) => {
    try {
        let userEmail = getState().auth.data.email;
        if (!userEmail) {
            userEmail = "noEmail";
        }
        await uploadScreenshotToAWS(screenshot, userEmail);
        dispatch(addScreenshot(screenshot));
        dispatch(setLastScreenshotTime(Date.now()));
    } catch (error) {
        console.error("Error uploading screenshot:", error);
    }
};