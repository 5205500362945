import { useState } from "react";
import { useForm } from "react-hook-form";
import { Label } from "flowbite-react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../utils/components/grapes/atoms/button/Button";
import { EyeClosed, Eye } from 'solar-icon-set';
import Tooltip from "../../../utils/components/grapes/atoms/tooltip/Tooltip";

interface LoginFormData {
    email: string;
    password: string;
}

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    password: Yup.string()
        .required("Password is required")
        .test('server-validation', 'Invalid email or password', () => true),
});

interface LoginFormProps {
    onSubmit: (data: LoginFormData) => Promise<void>;
    title?: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, title }) => {
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    const { register, handleSubmit, formState: { errors }, setError } = useForm<LoginFormData>({
        mode: "onBlur",
        resolver: yupResolver(loginSchema),
    });

    const handleFormSubmit = async (data: LoginFormData) => {
        setLoading(true);
        try {
            await onSubmit(data);
        } catch (error: any) {
            setError("password", {
                type: "server",
                message: error.message || "Invalid email or password. Please try again."
            });
        } finally {
            setLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            {title && <h2 className="text-2xl lg:text-3xl font-bold mb-4 lg:mb-6">{title}</h2>}
            <div className="mb-4">
                <Label htmlFor="email" value="Your email" />
                <input
                    id="email"
                    type="email"
                    placeholder="name@flowbite.com"
                    {...register("email")}
                    className="w-full bg-gray-100 border border-gray-800 rounded-md p-2"
                />
                {errors.email && (
                    <p className="text-red-500 text-xs italic mt-1">{errors.email.message}</p>
                )}
            </div>
            <div className="mb-4">
                <Label htmlFor="password" value="Your password" />
                <div className="relative">
                    <input
                        id="password"
                        type={passwordShown ? "text" : "password"}
                        placeholder="Password"
                        {...register("password")}
                        className="sensitive w-full bg-gray-100 border border-gray-800 rounded-md p-2"
                    />
                        <span
                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                            onClick={togglePasswordVisibility}
                        >
                            <Tooltip content={passwordShown ? "Hide password" : "Show password"}>
                                {passwordShown ? (
                                    <EyeClosed className="h-5 w-5 text-gray-500" />
                                ) : (
                                    <Eye className="h-5 w-5 text-gray-500" />
                                )}
                            </Tooltip>
                        </span>
                </div>
                {errors.password && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-3 py-3 mt-4 rounded flex items-center">
                        <FontAwesomeIcon icon={faExclamationCircle} className="mr-3" />
                        <p className="text-red-500 text-sm font-bold">{errors.password.message}</p>
                    </div>
                )}
            </div>
            <div className="flex items-center justify-between">
                <a
                    className="inline-block align-baseline text-xs text-light-blue-400 hover:text-blue-800 underline"
                    href="/forgot-password"
                >
                    Forgot Password?
                </a>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={loading}
                    className="mt-2"
                >
                    {loading ? (
                        <div role="status">
                            <svg
                                aria-hidden="true"
                                className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        <p>Sign In</p>
                    )}
                </Button>
            </div>
            <hr className="my-6"></hr>
                <p className="text-gray-600 mb-[-1rem] text-center">Don't have an account? <a href="https://heypathfinder.com/contact" className="text-blue-500 hover:underline">Contact us</a></p>
        </form>
    );
};

export default LoginForm;