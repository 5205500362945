import React, { useState, useEffect } from 'react';
import * as SolarIcons from 'solar-icon-set';
import { useProject } from '../../project/export';
import { useUser } from '../export';
import { Project } from '../state';
import { useSession } from '../../session/export';
import { Skeleton } from '@mui/material';
import { useAuth } from '../../auth/export';
import { initState as authInitState } from '../../auth/state';
import mixpanel from 'mixpanel-browser';
import Tooltip from '../../../grapes/atoms/tooltip/Tooltip';
import Dropdown, { DropdownOption } from '../../../grapes/molecules/dropdown/Dropdown';
import SolarAddSquare from "../../../../../assets/images/Solar Add Square Linear.png"

interface ProjectHistoryDropdownProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectHistoryDropdown: React.FC<ProjectHistoryDropdownProps> = ({ isOpen, setIsOpen }) => {
    const project = useProject();
    const user = useUser();
    const auth = useAuth();
    const session = useSession();

    const [currentProjectOption, setCurrentProjectOption] = useState<DropdownOption | undefined>(undefined);

    useEffect(() => {
        if (project.data.title) {
            setCurrentProjectOption({
                value: project.data.threadId || '',
                label: project.data.title.length > 23
                    ? `${project.data.title.substring(0, 23)}...`
                    : project.data.title,
                image: project.data.image.url || '',
            });
        } else {
            setCurrentProjectOption(undefined);
        }
    }, [project.data]);

    const openProject = (p: Project) => {
        // Mixpanel tracking for new chat created
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Project Revisited', {
                $email: auth.state.data.email,
                project: p.projectTitle
            });
        }
        session.thunks.newSession(p.threadId);
    };

    const newThread = () => {
        // Mixpanel tracking for new chat created
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('New Chat Created', {
                $email: auth.state.data.email
            });
        }
        session.thunks.newSession(undefined)
        setIsOpen(!isOpen)
    };

    const dropdownOptions: DropdownOption[] = [
        {
            value: 'new',
            label: 'Create a new project',
            image: SolarAddSquare,
            onClick: newThread
        },
        ...user.state.data.projects.map((project): DropdownOption => ({
            value: project.threadId,
            label: project.projectTitle.length > 0
                ? (project.projectTitle.length > 23
                    ? `${project.projectTitle}`
                    : project.projectTitle)
                : '',
            image: project.imageUrl || '',
            onClick: () => openProject(project)
        })).filter(option => option.label !== '')
    ];

    const handleDropdownChange = (selectedOptions: DropdownOption[]) => {
        if (selectedOptions.length > 0) {
            // Track the dropdown being opened
            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('Project History Dropdown Opened', { email: auth.state.data.email });
            }

            // Call the onClick handler of the selected option
            selectedOptions[0].onClick?.();
        }
    };

    return (
        <div className="relative z-20">
            <Dropdown
                options={dropdownOptions}
                placeholder="See your previous projects..."
                className="!w-[265px]"
                onChange={handleDropdownChange}
                value={currentProjectOption ? [currentProjectOption] : undefined}
                showSearch={false} // Add this line to disable search
            />
        </div>
    );
};

export default ProjectHistoryDropdown;