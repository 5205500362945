import axios from "axios";
import { toastSlice } from "../../toast/slice";
import { FIREBASE_BASE_URL, Thunk } from "../../utils";
import { ProjectFile } from "./state";
import { projectUploadSlice } from "./slice";
import { stepsSlice } from "../../steps/slice";
import assert from "assert";
import { assistantSlice } from "../../assistant/slice";
import { modalSlice } from "../../modal/slice";
import mixpanel from "mixpanel-browser";
import { panelSlice } from "../../panel/slice";
import { widgetSlice } from "../../widget/slice";
import { AssistantThunks } from "../../assistant/thunks";
import { API } from "../../assistant/api";
import { AnswerType } from "../../steps/state";

export namespace ProjectUploadThunks {

    export const getS3UploadUrlWithStepThunk: Thunk<File> = (file) => async (dispatch, getState) => {
        try {
            logger.log("[PROJECT UPLOAD THUNK] 🔵 Getting S3 upload URL");
            const session = getState().session.data;
            const link = await axios.get(`${FIREBASE_BASE_URL}/getS3ProjectUploadUrl`, {
                params: {
                    fileType: file.type,
                    userEmail: "",
                    projectName: `ProjectName_${session.thread?.thread_id}`,
                    projectStep: "Project step"
                }
            });

            const { uploadUrl, key } = link.data;
            return { uploadUrl, key, type: file.type, name: file.name };
        } catch (error: any) {
            logger.error("[PROJECT UPLOAD THUNK] 🔴 Error getting S3 upload URL", error);
            throw error;
        }
    };

    export const uploadFileToS3Thunk: Thunk<ProjectFile & { file: File }> = ({ file, bucket, step }) => async (dispatch) => {
        try {
            logger.log("[PROJECT UPLOAD THUNK] 🔵 Uploading file to S3");
            const response = await axios.put(bucket!.uploadUrl, file, {
                headers: { "Content-Type": file.type },
            });

            if (response.status === 200) {
                logger.log("File uploaded successfully.", bucket!.uploadUrl);
                if (step) {
                    dispatch(stepsSlice.actions.addAnswer({
                        stepId: step.id,
                        answer: { type: AnswerType.file, content: bucket!.key }
                    }));
                }
            } else {
                throw new Error("Failed to upload file to S3");
            }
        } catch (error: any) {
            logger.error("[PROJECT UPLOAD THUNK] 🔴 Error uploading file to S3", error);
            throw error;
        }
    };

    export const uploadStepFileThunk: Thunk<ProjectFile & { file: File }> = ({ file }) => async (dispatch, getState) => {
        try {
            logger.log("[PROJECT UPLOAD THUNK] 🔵 Uploading file started");
            dispatch(projectUploadSlice.actions.setStatus('preparing'));
            const bucket: ProjectFile['bucket'] = await dispatch(getS3UploadUrlWithStepThunk(file));
            dispatch(projectUploadSlice.actions.setStatus('saving'));
            // const currentStep = getState().steps.steps.filter((s) => s.status === "DOING")[0]
            const steps = getState().steps.steps;
            const currentStep = steps
                .filter(s => s.status === "DOING" || s.status === "DONE")
                .sort((a, b) => new Date(b.created_time).getTime() - new Date(a.created_time).getTime())[0];

            if (!currentStep) {
                throw new Error("No current step found");
            }
            // if (currentStep.length === 1) {
            await dispatch(uploadFileToS3Thunk({ file, bucket, step: currentStep }));
            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('Step File Uploaded', { fileType: file.type, fileName: file.name });
            }
            dispatch(AssistantThunks.sendToolCall({ toolName: "upload", content: bucket!.key }));
            dispatch(projectUploadSlice.actions.reset());
            dispatch(widgetSlice.actions.reset());
            // } else {
            //     console.log("There's no step in doing.")
            // }
            // dispatch(projectUploadSlice.actions.addUploadToFiles({ bucket, step: getState().upload.stepToUpload }));
            // dispatch(stepsSlice.actions.setDone())
            // dispatch(projectUploadSlice.actions.setStep(undefined))
            // dispatch(assistantSlice.actions.createMessage({
            //     content: { message: "Wow! That's amazing! Congratulations on completing that step! Let's work out the next one!" },
            //     role: "ai"
            // }));
        } catch (error: any) {
            logger.error("[PROJECT UPLOAD THUNK] 🔴 Error in upload file process", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to upload file",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(projectUploadSlice.actions.setStatus('ok'));
            logger.log("[PROJECT UPLOAD THUNK] 🟢 Uploaded file successfully");
        }
    };

    export const openUploadWidget: Thunk<void> = () => async (dispatch, getState) => {
        dispatch(widgetSlice.actions.setState('upload'))
    }


}