import { client } from "../../../init/pathFinderInit";
import axios from "axios";
import { FIREBASE_BASE_URL } from "../utils";
import * as fal from "@fal-ai/serverless-client";

export namespace API {

    export const createThread = async () => {
        logger.log("Attempting to create a new thread...");
        const response = await client.threads.create();
        logger.log("Thread created successfully:", response);
        return response;
    }

    export const stream = (threadId: string, message: string | null, role: string, metadata: { userEmail: string, uploadedImage: string, isSocratic: boolean, isScreenSharing: boolean }) => {
        logger.log("[ASSISTANT API] 🔵 Streaming message to assistant", message, metadata);

        const streamResponse = client.runs.stream(
            threadId,
            "agent",
            {
                input: message === null ? null : {
                    "messages": [
                        {
                            "role": role,
                            "content": message,
                        }
                    ]
                },
                streamMode: "events",
                multitaskStrategy: "interrupt",
                metadata: metadata,
                // config: {
                //     configurable: {
                //         userEmail: email
                //     }
                // }
            }
        );
        return streamResponse
    }

    export const getThreadState = async (threadId: string) => {
        try {
            const response: any = await client.threads.getState(threadId) // Ensure threadId is valid
            // logger.log(response)
            return response;
        } catch (error) {
            logger.error("Error fetching thread state:", error);
            throw error; // Handle error appropriately
        }
    }

    export const updateLastLoggedIn = async (email: string, threadId: string) => {
        try {
            const response = await axios.post(`${FIREBASE_BASE_URL}/updateLastLoggedIn`, {
                email: email.toLowerCase(),
                threadId: threadId
            });
            return response.data;
        } catch (error) {
            logger.error("Error updating last logged in:", error);
            throw error;
        }
    }

    export const updateLastMessaged = async (threadId: string) => {
        try {
            const response = await axios.post(`${FIREBASE_BASE_URL}/updateLastMessaged`, {
                threadId: threadId
            });
            return response.data;
        } catch (error) {
            logger.error("Error updating last logged in:", error);
            throw error;
        }
    }

    export const uploadFile = async (file: File, threadId: string, userEmail: string, userMsg: string) => {
        try {
            const response = await axios.get(`${FIREBASE_BASE_URL}/getS3ChatImageUploadUrl`, {
                params: {
                    fileType: file.type,
                    userMsg: userMsg,
                    threadId: threadId,
                    userEmail: userEmail
                }
            });
            // Return response.data directly
            return response.data;
        } catch (error) {
            logger.error("Error getting upload URL:", error);
            throw error;
        }
    }

    export const getImageURLFromAWS = async (key: string) => {
        try {
            const response = await axios.get(`${FIREBASE_BASE_URL}/getS3ProjectFile`, {
                params: {
                    key: key
                }
            });
            return response.data;
        } catch (error) {
            logger.error("Error getting image URL from AWS:", error);
            throw error;
        }
    }

    export const incrementNumMessages = async (threadId: string) => {
        try {
            const response = await axios.post(`${FIREBASE_BASE_URL}/addProjectPlanToUser`, {
                threadId: threadId,
                numMessages: { increment: 2 }
            });
            return response.data;
        } catch (error) {
            logger.error("Error incrementing numMessages:", error);
            throw error;
        }
    }

    export const incrementTimesListened = async (threadId: string) => {
        try {
            const response = await axios.post(`${FIREBASE_BASE_URL}/addProjectPlanToUser`, {
                threadId: threadId,
                timesListened: { increment: 1 }
            });
            return response.data;
        } catch (error) {
            logger.error("Error incrementing timesListened:", error);
            throw error;
        }
    }

    fal.config({
        credentials: process.env.REACT_APP_FAL_API_KEY,
    });
    const generateImage = async (prompt: string): Promise<string> => {
        try {
            const result = await fal.subscribe("fal-ai/flux/schnell", {
                input: {
                    prompt: prompt,
                    "enable_safety_checker": true
                },
                logs: true,
                onQueueUpdate: (update: any) => {
                    if (update.status === "IN_PROGRESS") {
                        update.logs.map((log: any) => console.log(log.message));
                    }
                },
            });

            // Type assertion to define the expected structure of the result
            const typedResult = result as { images?: { url: string }[] };

            if (typedResult.images && typedResult.images.length > 0) {
                return typedResult.images[0].url;
            } else {
                throw new Error("No image generated");
            }
        } catch (error) {
            logger.error("Error generating image:", error);
            throw error;
        }
    };

    export const saveProjectTitle = async (threadId: string, projectTitle: string) => {
        try {
            const capitalizedProjectTitle = projectTitle.charAt(0).toUpperCase() + projectTitle.slice(1);
            const imagePrompt = `A simple icon representing: ${capitalizedProjectTitle}`;
            let imageUrl = "pfLogo";

            try {
                imageUrl = await generateImage(imagePrompt);
                logger.log("Image URL:", imageUrl);
            } catch (imageError) {
                logger.error("Error generating image, using pfLogo instead:", imageError);
            }

            const response = await axios.post(`${FIREBASE_BASE_URL}/addProjectPlanToUser`, {
                threadId: threadId,
                projectTitle: capitalizedProjectTitle,
                imageUrl: imageUrl
            });
            return response.data;
        } catch (error) {
            logger.error("Error incrementing saveProjectTitle:", error);
            throw error;
        }
    }
}
