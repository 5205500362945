import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";
import './styles/customScrollbar.css';
import './utils/styles/responsive-text.css';
// import StateTestingPage from "./pages/StateTesting/StateTestingPage";
import ChatPage from "./pages/Chat/ChatPage";
import LoginPage from "./pages/Login/LoginPage";
import ForgotPassword from "./pages/Login/ForgotPassword"
import GuideDashboard from "./utils/components/ducks/user/components/guide/GuideDashboard";
import MainPage from "./pages/Main/Main";
import LearnerOverview from "./utils/components/ducks/user/components/guide/LearnerOverview";
import PathFinderInit from "./utils/init/pathFinderInit";
import ComponentGallery from './pages/ComponentGallery';

function App() {
    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<PathFinderInit><MainPage /></PathFinderInit>} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/guide-dashboard" element={<GuideDashboard />} />
                    <Route path="/guide-dashboard/learner/:email" element={<LearnerOverview />} />
                    {process.env.NODE_ENV === 'development' && (
                        <Route path="/component-gallery" element={<ComponentGallery />} />
                    )}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
