import React, { ButtonHTMLAttributes } from 'react';
import './button.css';
import '../../../../styles/responsive-text.css';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'error';
type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant;
  size?: ButtonSize;
  label?: string;
  loading?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
}

const Button: React.FC<ButtonProps> = ({
  variant,
  size = 'md',
  loading = false,
  icon,
  iconPosition = 'left',
  className,
  children,
  ...props
}) => {
  const baseClasses = 'button responsive-text';
  const variantClasses = `button--${variant}`;
  const sizeClasses = `button--${size}`;
  const loadingClasses = loading ? 'button--loading' : '';
  const iconClasses = icon ? `button--icon-${iconPosition}` : '';

  return (
    <button
      className={`${baseClasses} ${variantClasses} ${sizeClasses} ${loadingClasses} ${iconClasses} ${className || ''}`}
      disabled={loading || props.disabled}
      {...props}
    >
      {loading && (
        <span className="button__spinner">
          <svg className="responsive-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </span>
      )}
      {icon && <span className="button__icon responsive-icon">{icon}</span>}
      <span className="button__text">{children}</span>
    </button>
  );
};

export default Button;