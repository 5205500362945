import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Label, TextInput, Select, Textarea } from "flowbite-react";
import { Learner } from "./types";
import { API } from "../../../api";
import Button from "../../../../../grapes/atoms/button/Button";
import { boolean } from "yup";
import mixpanel from "mixpanel-browser";

const inputClassName = "p-2 flex-1 border bg-gray-100 rounded-md border-gray-300 focus:outline-none focus:border-blue-500";

interface StartProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  userEmail: string;
  learners: Learner[];
  setLearnersAdded: any;
}

const StartProjectModal: React.FC<StartProjectModalProps> = ({
  isOpen,
  onClose,
  userEmail,
  learners,
  setLearnersAdded,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deliverable, setDeliverable] = useState("");
  const [otherDeliverable, setOtherDeliverable] = useState("");
  const [selectedLearners, setSelectedLearners] = useState<string[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isDeliverableDropdownOpen, setIsDeliverableDropdownOpen] = useState(false);

  useEffect(() => {
    learners.sort((a, b) => a.email.localeCompare(b.email));
  }, [learners]);

  const handleAddAllLearners = () => {
    setSelectedLearners(learners.map(learner => learner.email));
  };

  const handleLearnerSelect = (email: string) => {
    setSelectedLearners(prev => 
      prev.includes(email) ? prev.filter(e => e !== email) : [...prev, email]
    );
  };

  const filteredLearners = learners.filter(learner =>
    learner.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedLearners([]);
    } else {
      setSelectedLearners(filteredLearners.map(learner => learner.email));
    }
    setSelectAll(!selectAll);
  };

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const finalDeliverable = deliverable === "Other" ? otherDeliverable : deliverable;

    try {
      setIsLoading(true);
      const savePromises = selectedLearners.map(learnerEmail =>
        API.saveProjectToLearner({
            learnerEmail,
            guideEmail: userEmail,
            projectTitle: title,
            projectDescription: description,
            projectDeliverable: finalDeliverable
        })
      );

      await Promise.all(savePromises);
      console.log("Project saved for all selected learners");
      onClose();
      setIsLoading(false);
      setTitle("");
      setLearnersAdded(selectedLearners);
      console.log("Learners added:", selectedLearners);
      setDescription("");
      setDeliverable("");
      setOtherDeliverable("");
      setSelectedLearners([]);
      setSelectAll(false);
      setIsDropdownOpen(false);
      setIsDeliverableDropdownOpen(false);
      // Mixpanel tracking for 'Guide using themed project'
      if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.track('Guide using themed project', {
          $email: userEmail,
          projectTitle: title,
          numberOfLearners: selectedLearners.length
        });
      }
    } catch (error) {
      console.error("Error saving project:", error);
      setIsLoading(false);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-70 flex items-center justify-center z-50 overflow-y-auto"
      onClick={onClose}
    >
      <div onClick={(e) => e.stopPropagation()} className="w-full max-w-2xl mx-auto my-8">
        <div className="bg-white p-8 rounded-md shadow-lg relative">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-700"
            aria-label="Close modal"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <h2 className="text-2xl font-bold mb-6">Create a New Project</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col space-y-2">
              <Label htmlFor="title">Project Title</Label>
              <input
                id="title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className={inputClassName}
                placeholder="Enter your project title here. E.g. Designing a country"
                required
              />
            </div>
            <div className="flex flex-col space-y-2">
              <Label htmlFor="description">Project Description</Label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={`${inputClassName}`}
                rows={4}
                placeholder="Enter your project description here. Make it 3+ sentences long. Mention any concepts you want them to cover, the goal/deadline date and any other important details."
              />
            </div>
            <div className="flex flex-col space-y-2">
              <Label htmlFor="deliverable">Deliverable</Label>
              <div className="relative">
                <button
                  type="button"
                  onClick={() => setIsDeliverableDropdownOpen(!isDeliverableDropdownOpen)}
                  className="w-full text-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {deliverable || "Select a deliverable"}
                  <FontAwesomeIcon icon={faChevronDown} className="absolute right-2 top-1/2 transform -translate-y-1/2" />
                </button>
                {isDeliverableDropdownOpen && (
                  <div className="absolute z-50 w-full bg-gray-100 rounded-md shadow-lg mt-1 mb-4 max-h-60 overflow-y-auto">
                    {["", "Mock Design", "Physical Model", "Other"].map((option, index) => (
                      <div
                        key={index}
                        className={`p-1.5 hover:bg-blue-100 cursor-pointer border-b border-gray-200 ${
                          deliverable === option ? "bg-blue-100" : ""
                        }`}
                        onClick={() => {
                          setDeliverable(option);
                          setIsDeliverableDropdownOpen(false);
                        }}
                      >
                        {option || "Select a deliverable"}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {deliverable === "Other" && (
              <div className="flex flex-col space-y-2">
                <Label htmlFor="otherDeliverable">Please specify the deliverable</Label>
                <input
                  id="otherDeliverable"
                  type="text"
                  value={otherDeliverable}
                  onChange={(e) => setOtherDeliverable(e.target.value)}
                  className={inputClassName}
                  placeholder="Enter your specific deliverable"
                  required
                />
              </div>
            )}
            <div className="relative">
              <Label htmlFor="learners">Learners</Label>
              <div className="flex space-x-2">
                <div className="relative w-full">
                  <button
                    type="button"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="w-full text-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {selectedLearners.length > 0 ? `${selectedLearners.length} learner(s) selected` : "Select learners"}
                    <FontAwesomeIcon icon={faChevronDown} className="absolute right-2 top-1/2 transform -translate-y-1/2" />
                  </button>
                  {isDropdownOpen && (
                    <div className="absolute z-50 w-full bg-gray-100 rounded-md shadow-lg mt-1 mb-4 max-h-60 overflow-y-auto">
                        <div className="sticky top-0 bg-white p-2 border-b border-gray-200">
                        <input
                            id="searchLearners"
                            type="text"
                            placeholder="Search learners..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className={`${inputClassName} mb-2`}
                        />
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="selectAll"
                            checked={selectAll}
                            onChange={handleSelectAll}
                            className="mr-2"
                          />
                          <label htmlFor="selectAll">Select All Learners</label>
                        </div>
                        </div>
                        {filteredLearners.map((learner, index) => (
                        <div
                            key={index}
                            className={`p-1.5 hover:bg-gray-100 cursor-pointer border-b border-gray-200 ${
                            selectedLearners.includes(learner.email) ? "bg-blue-100" : ""
                            }`}
                            onClick={() => handleLearnerSelect(learner.email)}
                        >
                            <input
                              type="checkbox"
                              checked={selectedLearners.includes(learner.email)}
                              onChange={() => {}}
                              className="mr-2"
                            />
                            {learner.name}
                        </div>
                        ))}
                    </div>
                    )}
                </div>
              </div>
            </div>
            {/* <TextInput
            id="selectedLearners"
            type="text"
            value={selectedLearners.join(", ")}
            onChange={() => {}} // This input is now read-only
            placeholder="Selected learners will appear here"
            className="mt-2"
            readOnly
            /> */}
            <div className="flex justify-end">
                <Button
                    variant="primary"
                    label="Create Project"
                    onClick={handleSubmit}
                    disabled={isLoading}
                    loading={isLoading}
                    className="mt-2"
                >
                  Create Project
                </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StartProjectModal;