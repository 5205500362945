import React, { useState, useEffect } from 'react';
import AuthButton from '../../../ducks/auth/components/authButton';
import Button from '../../atoms/button/Button';
import * as SolarIcons from 'solar-icon-set';
import pfLogo from '../../../../../assets/images/pf-icon.svg';
import WordToggle from '../../atoms/wordToggle';
import { useSession } from '../../../ducks/session/export';
import GuideDashboardButton from '../../../ducks/user/components/guide/DashboardButton';
import ProjectHistoryDropdown from '../../../ducks/user/components/ProjectHistoryDropdown';
import { useSteps } from '../../../ducks/steps/export';
import { useProject } from '../../../ducks/project/export';
import * as Icons from 'solar-icon-set'
import SuggestFeatureModal from '../suggestFeatureModal';
import Tooltip from '../../atoms/tooltip/Tooltip';
import IconButton from '../../atoms/iconButton/IconButton';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../../../ducks/auth/export';

const Navbar: React.FC = () => {
    const session = useSession()
    const { steps } = useSteps()
    const auth = useAuth()
    const [isSuggestFeatureModalOpen, setIsSuggestFeatureModalOpen] = useState(false)
    const [isProjectHistoryOpen, setIsProjectHistoryOpen] = useState(false)

    const currentTask = steps.find(step => step.status === 'DOING') || null

    useEffect(() => {
        const handleClickOutside = () => {
            setIsProjectHistoryOpen(false);
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <nav className={`flex items-center px-4 bg-white shadow-sm min-h-16`}>
            <div className="flex justify-start items-center space-x-4">
                <Tooltip content="Project Flow" position="bottom">
                    <img src={pfLogo} alt="Logo" className="w-8 h-8" />
                </Tooltip>
                <ProjectHistoryDropdown 
                    isOpen={isProjectHistoryOpen} 
                    setIsOpen={setIsProjectHistoryOpen} 
                />
                <Tooltip content="New Project" position="bottom">
                    <IconButton
                        variant={'tertiary'}
                        onClick={(() => {
                            // Mixpanel tracking for new chat created
                            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                                mixpanel.track('New Chat Created', {
                                    $email: auth.state.data.email
                                });
                            }
                            session.actions.reset()
                        })}
                        icon={<Icons.AddSquare size={24} />}
                        className="!border-none !ml-[-6px] mb-1"
                    />
                </Tooltip>
            </div>
            <div className="flex flex-grow justify-end items-center space-x-4">
                {currentTask && (
                    <Tooltip content="Current Task" position="bottom">
                        <div className="xl:flex hidden justify-end bg-yellow-100 px-4 py-2 rounded max-w-[20vw] overflow-hidden whitespace-nowrap text-ellipsis flex-shrink-0">
                            <p className='font-bold truncate'>{`Current Task: `}<span className='font-normal truncate'>{`${currentTask!.title}`}</span></p>
                        </div>
                    </Tooltip>
                )}
                <div className='flex items-center justify-end flex-row h-[55px] w-full space-x-2 xl:space-x-4 min-w-[625px]'>
                    <WordToggle
                        leftSelected={session.view === "Board"}
                        leftLabel="Board"
                        rightLabel="Chat"
                        onLeft={() => { 
                            session.actions.setView('Board');
                            // Mixpanel tracking for new chat created
                            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                                mixpanel.track('Switched View', {
                                    $email: auth.state.data.email,
                                    view: 'Board'
                                });
                            }
                        }}
                        onRight={() => { 
                            session.actions.setView('Chat');
                            // Mixpanel tracking for switched view
                            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                                mixpanel.track('Switched View', {
                                    $email: auth.state.data.email,
                                    view: 'Chat'
                                });
                            }
                        }}
                    />
                    <div className="flex items-center justify-center">
                        <Tooltip content="Tell us what you're thinking" position="bottom">
                            <Button
                                variant="tertiary"
                                size="md"
                                label="Give Feedback"
                                onClick={() => {
                                    setIsSuggestFeatureModalOpen(true);
                                }}>
                                <p>Give Feedback</p>
                            </Button>
                        </Tooltip>
                    </div>
                    <GuideDashboardButton />
                    <AuthButton />
                </div>
            </div>
            <SuggestFeatureModal isOpen={isSuggestFeatureModalOpen} onClose={() => setIsSuggestFeatureModalOpen(false)} />
        </nav>
    );
};

export default Navbar;