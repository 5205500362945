import { Dispatch } from "redux";
import { RootState, store } from "../../init/reduxInit";
import * as SolarIconSet from "solar-icon-set";
import { ProjectImagesThunks } from "./project/images/thunks";
import { AssistantThunks } from "./assistant/thunks";
import { Thunk, unknownFunction } from "./utils";
import { ProjectResourcesThunks } from "./project/resources/thunks";
import { ProjectInspirationsThunks } from "./project/inspirations/thunks";
import { ProjectThunks } from "./project/thunks";
import { OptionsThunks } from "./options/thunks";
import { projectResourcesSlice } from "./project/resources/slice";
import { StepsThunks } from "./steps/thunks";
import mixpanel from "mixpanel-browser";
import React, { ReactNode } from "react";
import ResourceSticker from "./project/resources/components/Sticker";
import StepsSticker from "./steps/components/stickers/StartStepSticker";
import EndStepsSticker from "./steps/components/stickers/EndStepSticker";
import Sticker from "../grapes/molecules/stickers/Sticker";
import { ProjectUploadThunks } from "./project/progress-upload/thunks";
import { version } from "os";
import UploadSticker from "./project/progress-upload/components/Sticker";
import { panelSlice } from "./panel/slice";
import { PanelState } from "./panel/state";
import { widgetSlice } from "./widget/slice";
import { WidgetState } from "./widget/state";

type FunctionMapItem = {
    func: (dispatch: typeof store.dispatch, args: any) => any;
    dontReplenish?: boolean;
    sticker?: (version: number, args?: any) => JSX.Element;
    state?: string; // describes the state that panel and widget use
    versioning?: {
        get: (getState: () => RootState) => any;
        set: (pageNumber: number) => any;
    }
};

export const functionMap: Record<string, FunctionMapItem> = {
    searchLearnModeImages: {
        func: (dispatch, args) => dispatch(ProjectImagesThunks.addImages(args)),
        sticker: (version: number) =>
            <Sticker
                icon={SolarIconSet.Gallery}
                friendly_name="Search Images"
                state="images"
            // toolVersion={version}
            />,
        state: "images"
    },
    searchVideoAndArticleResources: {
        func: (dispatch, args) => dispatch(ProjectResourcesThunks.addPage(args)),
        sticker: (version: number) => <ResourceSticker
            toolVersion={version}
        />,
        state: "resources",
        versioning: {
            get: (getState) => getState().resources.resourcePages.length,
            set: (pageNumber) => projectResourcesSlice.actions.setPage(pageNumber),
        }
    },
    generateDefineModeProjectInspirations: {
        func: (dispatch, args) => dispatch(ProjectInspirationsThunks.addInspirations(args)),
        sticker: (version: number) => <Sticker
            icon={SolarIconSet.HandStars}
            friendly_name="Generate Fun Projects"
            state="inspirations"
        />,
        state: "inspirations"
    },
    generateOneFineGrainedProjectGoalDescriptionImage: {
        func: (dispatch, args) => dispatch(ProjectThunks.setProjectThunk(args)),
        sticker: (version: number) => <Sticker
            icon={SolarIconSet.Star}
            friendly_name="Create Your Project"
            state="summary"

        />,
        state: "summary"
    },
    generatePotentialOptionsWhenStuck: {
        dontReplenish: true,
        func: (dispatch, args) => dispatch(OptionsThunks.addOptions(args)),
        sticker: (version: number) => <Sticker
            icon={SolarIconSet.BranchingPathsUp}
            friendly_name="Show Potential Paths"
            state="options"
        />,
        state: "options"
    },
    generateCandidateNextDefineStep: {
        dontReplenish: true,
        func: (dispatch, args) => dispatch(StepsThunks.addStepThunk(args)),
        sticker: (version: number, args: any) => <StepsSticker args={args} />,
        // state: "steps"
    },
    upload: {
        dontReplenish: true,
        func: (dispatch, args) => {
            // dispatch(ProjectUploadThunks.openUploadWidget());
            // dispatch(StepsThunks.setDoneThunk())
        },
        sticker: (version: number, args: any) => <UploadSticker imageKey={JSON.parse(args)?.answer?.content || ""} />,
        state: "upload"
    },
    // this is our one, we use this
    state_transition: {
        dontReplenish: true,
        func: (dispatch, args) => handleTransitions(dispatch, args),
        sticker: (version: number, args: any) => transitionSticker(version, args)
    },
    // this is the one the AI uses. We don't want to show this.
    transition_state: {
        func: (dispatch, args) => { },
        // sticker: (version: number) => <></>
    },
    notifySafetyRisk: {
        dontReplenish: true,
        func: (dispatch, args) => { },
    },
    getSuggestedActions: {
        func: (dispatch, args) => dispatch(AssistantThunks.updateSuggestedActions(args)),
    },
    getProjectKanbanState: {
        dontReplenish: true,
        func: (dispatch, args) => { },
    },
    unknownFunction: {
        func: (dispatch, args) => dispatch(unknownFunction(args)),
    }
};

export const handleFunctionCall = (
    dispatch: Dispatch,
    functionName: string,
    args: any,
    replenish: boolean,
): void => {
    const func = functionMap[functionName as keyof typeof functionMap];
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.track('Function Called', { name: functionName });
    }
    if (func) {
        console.log("Function called:", functionName, args);
        if (!(replenish && func.dontReplenish)) {
            console.log("Run func:", functionName)
            return func.func(dispatch, args);
        }
    } else {
        console.log(`🔴 Unknown function: ${functionName}`);
    }
};

export const transitionSticker = (
    version: number,
    args: any
) => {
    const { mode, prev_mode } = args
    console.log(`Transition sticker: ${prev_mode} -> ${mode}`);
    console.log('the args where: ', args)
    switch (true) {
        case (mode === 'DEFINE_STEP' && prev_mode === "LEARN_BUILD_UPLOAD"):
            return <EndStepsSticker />
        default:
            console.log(`Unknown transition: ${prev_mode} -> ${mode}`);
            // return (<p>{`Unknown transition: ${prev_mode} -> ${mode}`}</p>)
            return <></>
    }
}

export const handleTransitions = (
    dispatch: typeof store.dispatch,
    args: any
) => {
    const { mode, prev_mode } = args
    console.log(`Transition called: ${prev_mode} -> ${mode}`);
    switch (true) {
        case (mode === 'DEFINE_STEP' && prev_mode === "LEARN_BUILD_UPLOAD"):
            dispatch(StepsThunks.setDoneThunk());
            break;
        default:
            console.log(`Unknown transition: ${prev_mode} -> ${mode}`);
            break;
    }
}