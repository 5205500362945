import React, { useState, useRef, useEffect } from 'react';
import './dropdown.css';
import { AltArrowDown } from 'solar-icon-set';
import '../../../../styles/responsive-text.css';
import pfLogo from '../../../../../assets/images/PP Square Light.jpg';
import Tooltip from '../../atoms/tooltip/Tooltip';

export interface DropdownOption {
  value: string;
  label: string;
  image?: string;
  onClick?: () => void;
}

interface DropdownProps {
  options: DropdownOption[];
  placeholder?: string;
  onChange?: (selectedOptions: DropdownOption[]) => void;
  className?: string;
  multiSelect?: boolean;
  showSearch?: boolean;
  value?: DropdownOption[];
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  placeholder = 'Select an option',
  onChange,
  className,
  multiSelect = false,
  showSearch = true,
  value
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<DropdownOption[]>(value || []);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  const handleOptionClick = (option: DropdownOption) => {
    if (option.onClick) {
      option.onClick();
    }

    let newSelectedOptions: DropdownOption[];
    if (multiSelect) {
      newSelectedOptions = selectedOptions.some(opt => opt.value === option.value)
        ? selectedOptions.filter(opt => opt.value !== option.value)
        : [...selectedOptions, option];
    } else {
      newSelectedOptions = [option];
      setIsOpen(false);
    }
    setSelectedOptions(newSelectedOptions);
    onChange?.(newSelectedOptions);
  };

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelectAll = () => {
    const newSelectedOptions = selectedOptions.length === filteredOptions.length ? [] : filteredOptions;
    setSelectedOptions(newSelectedOptions);
    onChange?.(newSelectedOptions);
  };

  const renderSelectedContent = () => {
    if (multiSelect) {
      return <span className="responsive-text">{`${selectedOptions.length} option(s) selected`}</span>;
    } else if (selectedOptions.length > 0) {
      const selectedOption = selectedOptions[0];
      return (
        <div className="flex items-center">
          {selectedOption.image && (
            <img
              src={selectedOption.image === "pfLogo" ? pfLogo : selectedOption.image}
              alt={selectedOption.label}
              className="dropdown-selected-image w-10 h-10 mr-2 rounded-sm"
            />
          )}
          <span className="dropdown-selected-label responsive-text">{selectedOption.label}</span>
        </div>
      );
    }
    return <span className="dropdown-placeholder responsive-text-minus">{placeholder}</span>;
  };

  return (
    <div className={`dropdown ${className || ''}`} ref={dropdownRef}>
      <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        {renderSelectedContent()}
        <AltArrowDown className={`dropdown-arrow responsive-icon ${isOpen ? 'open' : ''}`} />
      </div>
      {isOpen && (
        <div className="dropdown-options w-full">
          {showSearch && (
            <div className="dropdown-search w-full p-2">
              <input
                type="text"
                placeholder="Search options..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                className="responsive-text w-full p-2 border border-gray-S2 rounded"
              />
            </div>
          )}
          {multiSelect && (
            <div className="dropdown-select-all w-full p-2 flex items-center hover:bg-gray-S1" onClick={handleSelectAll}>
              <input
                type="checkbox"
                checked={selectedOptions.length === filteredOptions.length}
                readOnly
                className="mr-2"
              />
              <span className="responsive-text">Select All</span>
            </div>
          )}
          <div className="dropdown-options-list w-full">
            {filteredOptions.map((option) => (
              <Tooltip
                key={option.value}
                content={option.label}
                position="bottom"
              >
                <div
                  className={`dropdown-option w-full p-2 hover:bg-gray-S1 ${
                    selectedOptions.some(opt => opt.value === option.value) ? 'bg-gray-T1' : ''
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  <div className="dropdown-option-content flex items-center w-full">
                    {multiSelect && (
                      <input
                        type="checkbox"
                        checked={selectedOptions.some(opt => opt.value === option.value)}
                        readOnly
                        className="dropdown-checkbox mr-2"
                      />
                    )}
                    {option.image && (
                      <img
                        src={option.image === "pfLogo" ? pfLogo : option.image}
                        alt={option.label}
                        className="dropdown-option-image w-10 h-10 mr-2 rounded-sm"
                      />
                    )}
                    <span className="dropdown-option-label flex-grow">{option.label}</span>
                  </div>
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;